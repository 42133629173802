import React from "react";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    const padStartLinks = [
      {
        to: "https://energytalkingpoints.com",
        text: "Energy Talking Points",
        external: true,
      },
      {
        to: "https://industrialprogress.com",
        text: "Center for Industrial Progress",
        external: true,
      },
      {
        to: "mailto:support@industrialprogress.net",
        text: "Contact",
        external: true,
      },
    ];
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    {padStartLinks.map((node) => (
                      <li key={node.to}>
                        {node.external ? (
                          <a
                            className="navbar-item"
                            href={node.to}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {node.text}
                          </a>
                        ) : (
                          <Link className="navbar-item" to={node.to}>
                            {node.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
