import React from "react";
import { Link } from "gatsby";

import epstein from "../img/epstein.jpeg";

const Navbar = class extends React.Component {
  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <div className="level" style={{ marginBottom: 0 }}>
              <Link to="/" className="navbar-item level-item" title="Logo">
                <img
                  src={epstein}
                  alt="Alex Epstein"
                  style={{
                    maxHeight: 60,
                    borderRadius: "50%",
                  }}
                />
                <div className="ml-3">
                  <p className="has-text-weight-bold">
                    Energy Talking Points On Demand
                  </p>
                  <p>by Alex Epstein</p>
                </div>
              </Link>
            </div>
          </div>
          <div id="navMenu" className={`navbar-menu`}>
            <div className="navbar-end has-text-centered">
              <Link
                target="blank"
                rel="noopener noreferrer"
                className="navbar-item"
                to={"https://energytalkingpoints.com"}
              >
                Energy Talking Points
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
